<template>
  <div id="editUserInternalPermissionModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <div class="form-row">
          <CCol tag="label" sm="3" class="col-form-label"> Permissions </CCol>
          <CCol sm="9">
            <template v-for="(permission, index) in permissionList">
              <div class="form-row form-check" :key="index">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="permission.value"
                  :value="permission.value"
                  v-model="checkedPermissions"
                />
                <label
                  :key="index"
                  :for="permission.value"
                  class="form-check-label"
                  >{{ permission.name }}</label
                >
              </div>
            </template>
            <!-- <span>Checked: {{ checkedPermissions }}</span> -->
          </CCol>
        </div>
      </CForm>
      <template #header>
        <h5 class="modal-title">Edit Permissions</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="secondary">Cancel</CButton>
        <CButton @click="onEdit" color="primary">Save</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { PermissionList } from '@/utils/utils';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    permissions: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      checkedPermissions: [...this.permissions],
    };
  },
  computed: {
    permissionList: function () {
      return PermissionList();
    },
  },
  methods: {
    closeModal() {
      this.checkedPermissions = [...this.permissions];
      this.$emit('close');
    },
    onEdit() {
      this.$emit('save', this.checkedPermissions);
    },
  },
};
</script>
